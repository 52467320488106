import JoppysImage, { icAbout } from "img/JoppysImage"
import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function About() {
  return (
    <Container className="py-5">
      <Row className="g-3">
        <Col md={8}>
          <Card className="p-4 p-md-5 rounded-4 text-center">
            <h4 className="mb-5">Cos'è Joppys</h4>
            <h1 className="mb-5">Un'unica app per semplificare la vita con il tuo animale</h1>
            <div className="text-larger">
              Dalla gestione quotidiana alla ricerca del servizio più adatto a te a al tuo animale, tutto questo e molto
              ancora su Joppys.
            </div>
          </Card>
        </Col>
        <Col md={4} className="d-md-flex">
          <Card className="p-4 p-md-5 rounded-4 text-center">
            <h4 className="mb-4">Scopri Joppys</h4>
            <Row className=" g-3">
              <Col>
                <Link to="/">
                  <Button variant="light" className="w-100">
                    Proprietari di animali
                  </Button>
                </Link>
              </Col>
              <div className="col-12">
                <Link to="/business">
                  <Button variant="light" className="w-100">
                    Professionisti del settore
                  </Button>
                </Link>
              </div>
            </Row>
          </Card>
        </Col>
        <Col>
          <Card className="p-4 p-md-5 rounded-4">
            <Row className="align-items-center gx-5">
              <Col md={7} className="text-center">
                <h4 className="mb-4">Chi siamo</h4>
                <div className="text-larger">
                  <p>
                    Uniti dalla passione per l'innovazione e dalla nostra personale esperienza, cerchiamo di costruire
                    un servizio unico in grado di facilitare la vita dei proprietari di animali e dei professionisti del
                    settore.
                  </p>
                  <p>
                    Il mondo del pet ha intrapreso la trasformazione digitale e noi siamo pronti a farne parte da
                    protagonisti.
                  </p>
                </div>
              </Col>
              <Col md={5}>
                <JoppysImage content={icAbout.Team} className="w-100 rounded-4" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
