import React from "react"

import {
  businessFieldNames,
  businessInfoFields as fields,
  FormState,
  InputGroupBox,
  setField,
} from "routes/business/BusinessForm"

export default function EditBusInfoForm({ formStatus, errors, form, setForm }) {
  return (
    <div>
      <InputGroupBox
        disabled={formStatus !== FormState.editing}
        value={form[businessFieldNames.title]}
        id={businessFieldNames.title}
        errors={errors}
        fields={fields}
        onChange={(e) => setField(e.target.id, e.target.value, setForm)}
      />
    </div>
  )
}
