import React from "react"
import GoogleMapsApiWrapper, { MyMapComponent } from "./GoogleMapsApi"

export default function MapNew3() {
  return (
    <GoogleMapsApiWrapper>
      <Content />
    </GoogleMapsApiWrapper>
  )
}

function Content() {
  const geocoder = new window.google.maps.Geocoder()
  console.log(geocoder)
  return <MyMapComponent></MyMapComponent>
}
