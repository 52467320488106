import { Status } from "@googlemaps/react-wrapper"
import AwaitNavigate from "common/AwaitNavigate"
import GoogleMapsApiWrapper, { geocodeAddress, Marker, MyMapComponent } from "common/GoogleMapsApi"
import SpinnerCentered from "common/SpinnerCentered"
import { useAuth } from "context/AuthContext"
import JoppysImage, { iMapPin } from "img/JoppysImage"
import React, { useState, useEffect } from "react"
import { Button, Card, Col, Container, Form, Row, ToggleButton } from "react-bootstrap"
import { Link } from "react-router-dom"
import { createBusinessDraft, useBusinessDraftDocSingle } from "scripts/private"

import { buildErrors, businessFields as fields, checkNoErrors } from "routes/business/BusinessForm"

function InputGroubBox({ id, as, value, onChange, errors, ...props }) {
  return (
    <Form.Group key={id} as={as} controlId={id} {...props}>
      <Form.Label>{fields[id].label}</Form.Label>
      <Form.Control type={fields[id].type ?? "text"} value={value} onChange={onChange} isInvalid={!!errors[id]} />
      {errors[id] !== " " && <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>}
    </Form.Group>
  )
}

function buildErrorsAddBusiness(form, fields) {
  const newErrors = buildErrors(form, fields)
  if (!form["type"]) newErrors["type"] = fields["type"].error
  if (!form["form.check"]) newErrors["form.check"] = " "
  return newErrors
}

export default function AddBusiness() {
  const { currentUser } = useAuth()
  const draftDoc = useBusinessDraftDocSingle(currentUser.uid)
  const [status, setStatus] = useState("")
  const [submitCount, setSubmitCount] = useState(0)
  const [addressChanged, setAddressChanged] = useState(false)
  const [markerCalculated, setMarkerCalculated] = useState(false)
  const [form, setForm] = useState({})
  const [geocoder, setGeocoder] = useState()
  // const hasSubmitCountChanged = useCompare(submitCount)

  let errors = {}

  if (submitCount) {
    errors = buildErrorsAddBusiness(form, fields)
  } else {
    errors = { submitted: false }
  }

  function setField(field, value) {
    let conditionalFields = {}
    if (fields[field]?.isAddressField) {
      setAddressChanged(true)
      conditionalFields = { "form.check": false }
    }
    setForm((currentForm) => {
      return {
        ...currentForm,
        [field]: value,
        ...conditionalFields,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!checkNoErrors(buildErrorsAddBusiness(form, fields))) {
      setSubmitCount((currentSubmitCount) => currentSubmitCount + 1)
      return false
    } else {
      try {
        await createBusinessDraft(form, currentUser.uid)
        setStatus("ok")
      } catch (e) {
        setStatus(e.code || "?")
      }
    }
  }

  useEffect(() => {
    if (submitCount) {
      const invalidFields = document.getElementsByClassName("is-invalid")
      if (invalidFields.length > 0) {
        invalidFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }
    }
  }, [submitCount])

  let content
  if (!draftDoc) {
    // loading
    content = <SpinnerCentered />
  } else if (draftDoc.data) {
    // refused
    content = (
      <div>
        {"Hai già aggiunto la tua attività. Verrai reindirizzato alla "}
        <AwaitNavigate to="/business/dashboard" seconds={3}>
          dashboard
        </AwaitNavigate>
        {"."}
        <br />
      </div>
    )
  } else {
    // ok

    if (status === "") {
      content = (
        <>
          <Form id="myform" noValidate onSubmit={handleSubmit} className="needs-validation">
            <div className="vstack gap-3 gap-lg-5">
              <Row>
                <Col lg={8} className="mx-auto text-center">
                  <div className="text-larger mb-2">
                    Crea la tua scheda professionale <b>gratuita</b> su Joppys!
                  </div>
                  <div>
                    In pochi passi potrai creare il tuo profilo digitale, inserire i tuoi contatti e le informazioni
                    base. Gli utenti potranno così individuarti facilmente attraverso la nostra piattaforma.
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center text-center gy-3 mt-5">
                <Col md={4} className="offset-md-4">
                  <div className="mb-4">Seleziona la categoria della tua attività</div>

                  <Form.Group>
                    <div className="vstack gap-2">
                      <ToggleButton
                        variant={form["type"] === "1" ? "primary" : "light"}
                        id="1"
                        type="radio"
                        name="xradio"
                        value="1"
                        // checked={radioValue === "1"}
                        onChange={(e) => setField("type", e.currentTarget.value)}
                      >
                        Veterinario
                      </ToggleButton>
                      <ToggleButton
                        variant={form["type"] === "2" ? "primary" : "light"}
                        id="2"
                        type="radio"
                        name="xradio"
                        value="2"
                        className={!!errors["type"] && "is-invalid"} // checked={radioValue === "2"}
                        onChange={(e) => setField("type", e.currentTarget.value)}
                      >
                        Toelettatore
                      </ToggleButton>

                      <Form.Control.Feedback type="invalid">{errors["type"]}</Form.Control.Feedback>
                      {/* <Form.Control.Feedback type="invalid">You did it!</Form.Control.Feedback> */}
                    </div>
                  </Form.Group>
                  {/* <div className="invalid-feedback">È necessario selezionare la categoria della tua attività.</div> */}
                </Col>
                <Col md={4}>
                  <Card bg="light" className="text-center">
                    <Card.Body>
                      <p className="m-0">
                        Appartieni ad un'altra categoria del mondo degli animali?
                        <br />
                        <Link to="/contacts">Lasciaci la tua email</Link> e ti contatteremo appena ci saranno novità
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Col lg={8} className="mx-auto mt-5 text-center">
                <div className="text-larger mb-2">Informazioni pubbliche</div>
                <div>
                  I dati inseriti in questa scheda saranno riportati sulla scheda del tuo profilo e appariranno come
                  informazioni base nelle ricerche.
                </div>
              </Col>
              <div className="vstack gap-2 col-md-8 mx-auto">
                <InputGroubBox id="title" errors={errors} onChange={(e) => setField(e.target.id, e.target.value)} />
                <Row>
                  <InputGroubBox
                    id="address.street"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={8}
                  />
                  <InputGroubBox
                    id="address.house_number"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={4}
                  />
                </Row>
                <Row>
                  <InputGroubBox
                    id="address.city"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                </Row>
                <Row>
                  <InputGroubBox
                    id="address.district"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={7}
                  />
                  <InputGroubBox
                    id="address.postal_code"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={5}
                  />
                </Row>
              </div>
              <Col lg={8} className="mx-auto mt-5 text-center">
                <div className="text-larger mb-2">Controlla la posizione sulla mappa!</div>
                <div>
                  Per farti trovare è importante che la posizione della tua attività sia corretta. Posizioneremo il
                  cursore in base all'indirizzo che ci hai fornito. Se la posizione non è esatta, o vuoi indicarla con
                  più precisione, trascina il segnalino
                  <JoppysImage content={iMapPin} className="mx-2" />
                  {/* <img width="20" className="mx-2" alt="" src="google_maps_pin.svg" /> */}
                  sulla mappa.
                </div>
              </Col>

              <Row>
                <Col lg={8} className="mx-auto">
                  <GoogleMapsApiWrapper
                    callback={(status, loader) => {
                      if (status === Status.SUCCESS) {
                        loader.load().then((google) => {
                          !geocoder && setGeocoder(new google.maps.Geocoder())
                        })
                      }
                    }}
                  >
                    <div className="position-relative">
                      <MyMapComponent
                        className="rounded-3"
                        height={400}
                        zoom={17}
                        center={form["address.geo.location"]}
                      >
                        {form["address.geo.location"] && (
                          <Marker
                            draggable={true}
                            position={form["address.geo.location"]}
                            onDragEnd={(e) => {
                              setField("address.geo.location", e.latLng.toJSON())
                            }}
                          />
                        )}
                      </MyMapComponent>

                      {(addressChanged || !markerCalculated) && (
                        <div>
                          <div className="position-absolute w-100 h-100 top-50 start-50 translate-middle bg-dark opacity-75"></div>
                          <div className="position-absolute top-50 start-50 translate-middle bg-light rounded-4 p-4 text-center">
                            <p>
                              {" "}
                              {markerCalculated ? (
                                <>
                                  L'indirizzo è stato modificato. <br />
                                  Ricalcola la posizione del cursore.
                                </>
                              ) : (
                                <>Sulla base dell'indirizzo fornito verrà posizionato il segnalino sulla mappa</>
                              )}
                            </p>
                            <Button
                              onClick={(e) => {
                                const fullAddress = `${form["address.street"] ?? ""}, ${
                                  form["address.house_number"] ?? ""
                                }, ${form["address.postal_code"] ?? ""} ${form["address.city"] ?? ""} ${
                                  form["address.district"] ?? ""
                                }, Italy`

                                geocodeAddress(geocoder, fullAddress, (location) => {
                                  setField("address.geo.location", location.toJSON())
                                  setField("form.check", false)
                                  setAddressChanged(false)
                                  setMarkerCalculated(true)
                                })
                              }}
                            >
                              {markerCalculated ? "Aggiorna" : "Calcola posizione"}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </GoogleMapsApiWrapper>
                </Col>
              </Row>
              <Row>
                <Col lg={8} className="mx-auto d-flex justify-content-center">
                  <div className="d-inline-flex ">
                    <Form.Check>
                      <Form.Check.Input
                        checked={form["form.check"] ?? false}
                        disabled={addressChanged || !markerCalculated}
                        onChange={(e) => setField("form.check", e.target.checked)}
                        isInvalid={!!errors["form.check"]}
                      />
                      <Form.Check.Label>Confermo la posizione indicata sulla mappa</Form.Check.Label>
                    </Form.Check>
                  </div>
                </Col>
              </Row>
              <Col lg={8} className="mx-auto mt-5">
                <div className="text-center">
                  <div className="text-larger mb-2">Contatti</div>
                  <div>
                    Inserisci almeno un numero di telefono per farti sempre rintracciare da chi ricerca la tua attiità.
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4} className="mx-auto">
                <div className="vstack gap-2">
                  <InputGroubBox
                    id="phone.primary"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                  <InputGroubBox
                    id="phone.secondary"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={8} className="mx-auto mt-5">
                <div className="text-center">
                  <div className="text-larger mb-2">Rappresentante/Proprietario</div>
                  <div>
                    Al fine di offrire ai proprietari di animali informazioni certificate, è necessario inserire i dati
                    del rappresentante/proprietario del servizio professionale registrato.
                  </div>
                </div>
              </Col>
              <Row className="align-items-center gy-3">
                <Col md={4} className="order-md-last">
                  <Card bg="light" className="text-center">
                    <Card.Body>
                      <p className="m-0">
                        Questi dati non saranno visibili agli utenti, ma saranno necessari allo staff di Joppys per
                        verificare le informazioni.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="offset-md-4">
                  <div className="vstack gap-2">
                    <InputGroubBox
                      id="owner.name"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                    />
                    <InputGroubBox
                      id="owner.surname"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                    />
                    <InputGroubBox
                      id="owner.phone"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Col lg={8} className="mx-auto text-center mt-5">
                <b>Ci siamo quasi!</b>
                <br />
                Il nostro staff prenderà in carico la tua richiesta nelle prossime 48 ore.
              </Col>

              <Row>
                <Col xs={6} lg={4} className="mx-auto">
                  <Button type="submit" className="w-100 btn-block">
                    Salva
                  </Button>
                  {!!submitCount && Object.keys(errors).length !== 0 && (
                    <>
                      <div className="mt-2 invalid-feedback d-block text-center">Alcuni campi riultano incompleti</div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </>
      )
    } else if (status === "ok") {
      content = renderResult(status)

      function renderResult(status) {
        let title = ""

        let content
        switch (status) {
          case "ok":
            title = "Operazione riuscita!"
            content = <>Complimenti, hai inserito la tua attività!</>
            break
          // case "permission-denied":
          //   title = "Errore!"
          //   content = (
          //     <>
          //       Si è verificato un problema
          //     </>
          //   )
          //   break
          default:
            title = "Errore!"
            content = status
            break
        }
        return (
          <>
            <h3>{title}</h3>
            <div className="my-3">{content}</div>
            <div>
              {"Verrai reindirizzato alla "}
              <AwaitNavigate to="/business/dashboard" seconds={3}>
                dashboard
              </AwaitNavigate>
            </div>
          </>
        )
      }
    }
  }

  return (
    <Container>
      <Card className="rounded-4 p-5">{content}</Card>
    </Container>
  )
}
