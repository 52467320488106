import JoppysImage, { icHome } from "img/JoppysImage"
import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
// import PlayStoreBadge from "img/home/it_appstore_badge.svg"

function MiniCard(props) {
  const obj = props.data
  return (
    <Col>
      <div className="d-grid position-relative">
        {obj.head && (
          <span className="position-absolute py-1 start-50 translate-middle-x px-4 bg-secondary rounded-4">
            {obj.head}
          </span>
        )}
        <div>
          <JoppysImage content={obj.img} />
        </div>
        <h5>{obj.title}</h5>
        <div className="p-2">{obj.des}</div>
      </div>
    </Col>
  )
}

export default function Home() {
  const miniCardOwner = [
    {
      img: icHome.WhyJoppysPets,
      title: "Un'app per tutti",
      des: "Che tu abbia un cane o un drago barbuto, non importa Joppys farà al caso tuo",
    },
    {
      img: icHome.WhyJoppysSearch,
      title: "Trova servizi",
      des: "Cerca il servizio più adatto alle tue esigenze e alle necessità del tuo animale",
    },
    {
      img: icHome.WhyJoppysArchive,
      title: "Annota e archivia",
      des: "Organizza tutte le informazioni del tuo animale in un posto sicuro e sempre con te",
    },
    {
      img: icHome.WhyJoppysNews,
      title: "News",
      des: "Rimani aggiornato sulle ultime novità e curiosità dal mondo del pet",
    },
    {
      img: icHome.WhyJoppysTips,
      title: "Guide e consigli",
      des: "Ricevi guide e consigli per la gestione e cura del tuo animale domestico",
    },
    // {
    //   img: icHome.,
    //   title: "",
    //   des: ""
    // }
  ]

  const miniCardBusiness = [
    {
      img: icHome.WhyJoppysBusPagina,
      title: "Fatti conoscere",
      des: "Crea la tua pagina personale con tutte le informazioni relative alla tua attività",
    },
    {
      img: icHome.WhyJoppysBusUpdatedInfo,
      title: "Dati aggiornati",
      des: "Tieni sempre aggiornate le informazioni relative alla tua attività",
    },
    {
      img: icHome.WhyJoppysBusEasy,
      title: "Interfaccia intuitiva",
      des: "Crea i tuoi spazi in pochi passi con un servizio di assistenza sempre a disposizione",
    },
    {
      head: "Soon",
      img: icHome.WhyJoppysBusLoyalty,
      title: "Fidelizza i tuoi clienti",
      des: "Invia news, promozioni e contenuti rilevanti per i tuoi clienti",
    },
    {
      head: "Soon",
      img: icHome.WhyJoppysBusCalendar,
      title: "Gestione",
      des: "Gestisci i tuoi appuntamenti [...]",
    },
  ]

  return (
    <>
      <div className="bg-primary">
        <Container>
          <Row className="row justify-content-center pt-5 mx-auto">
            <Col sm={6} className="align-self-center text-center text-sm-start">
              <Row>
                <h1 className="text-white joppys-banner">semplifica</h1>
                <h1 className="joppys-banner">la vita con il tuo animale</h1>
              </Row>
              <Row className="my-4">
                <div className="h5">Unisciti a Joppys, scarica l'app per il tuo dispositivo</div>
              </Row>
              <Row className="justify-content-center justify-content-sm-start pb-4 gx-5 gy-4">
                <Col xs={6} lg={5} xl={4} xxl={3} className="align-self-center">
                  {/* <!-- https://play.google.com/store/apps/details?id=com.joppys.joppys --> */}
                  <Link to="/newsletter/app">
                    <JoppysImage content={icHome.PlayStoreBadge} className="w-100" />
                  </Link>
                </Col>
                <Col xs={6} lg={5} xl={4} xxl={3} className="align-self-center">
                  <Link to="/newsletter/app">
                    <JoppysImage content={icHome.AppStoreBadges} className="w-100" />
                    {/* <img className="w-100" alt="Disponibile su Google Play" src="/img/index/it_appstore_badge.png" /> */}
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col sm={6} className="order-sm-first align-self-end">
              <JoppysImage content={icHome.JoppysOwner} className="d-block w-100 mx-auto px-0 px-lg-3 px-xl-5" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-light">
        <Container className="text-center py-5">
          <Row className="justify-content-center py-3 mb-5">
            <Col sm={8} lg={6}>
              <h1 className="mb-3">
                Perché <b>Joppys</b>?
              </h1>
              <div className="text-larger">
                Proprietario di animale o professionista del settore?
                <br />
                Scopri come possiamo aiutarti
              </div>
            </Col>
          </Row>
          <Card className="rounded-4 p-4 mb-5">
            <h3 className="mb-3">Per i proprietari di animali</h3>
            <Row className="row-cols-2 row-cols-md-5 g-3 justify-content-center">
              {miniCardOwner.map((e, index) => (
                <MiniCard key={index} data={e} />
              ))}
            </Row>
          </Card>
          <Card className="rounded-4 p-4">
            <h3 className="mb-3">Per i professionisti</h3>
            <Row className="row-cols-2 row-cols-md-5 g-3 justify-content-center">
              {miniCardBusiness.map((e, index) => (
                <MiniCard key={index} data={e} />
              ))}
            </Row>
          </Card>
        </Container>
      </div>
      {/* business */}
      <div className="bg-body">
        <Container className="text-center">
          <Row className="justify-content-center py-5 mx-auto">
            <Col sm={6} className="align-self-center text-center text-sm-start">
              <div className="h1">Sei un professionista del settore?</div>
              <div className="text-larger my-5">
                Scopri i vantaggi di Joppys, come può aiutarti nel tuo lavoro e come può far crescere la tua attività
              </div>
              <Link to="/business">
                <Button size="lg" variant="secondary">
                  Scopri di più
                </Button>
              </Link>
            </Col>
            <div className="col-12 col-sm-6 align-self-end pt-5 pt-sm-0">
              <JoppysImage content={icHome.JoppysBusiness} className="d-block w-100 mx-auto px-0 px-lg-3 px-xl-5" />
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}
