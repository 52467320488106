import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore"
import { db } from "scripts/FirebaseApp"

import { useAuth } from "context/AuthContext"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"

const businessDraftPath = "business_draft"

export class CodeError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
  }

  static notFound = "not-found"

  isNotFound() {
    return this.code === CodeError.notFound
  }
}

// export async function subscribeNewsletter(docData) {
//   // email as document id
//   docData.date = serverTimestamp()
//   const newDocRef = doc(db, "public_forms", "newsletter", "app_launch", docData.email)
//   return setDoc(newDocRef, docData)
// }

export function convertDotNotation(docRaw, tag) {
  const separator = "."
  const asArray = Object.entries(docRaw)
  // filter out tag
  const filtered = asArray.filter(([key, value]) => !key.startsWith(tag + separator))

  docRaw = Object.fromEntries(filtered)

  let doc = {}
  for (const keyRaw in docRaw) {
    const myArray = keyRaw.split(separator)

    let obj = doc
    let len = myArray.length
    for (let i = 0; i < len - 1; i++) {
      if (myArray[i].endsWith("[]")) {
        myArray[i] = myArray[i].substring(0, myArray[i].length - 2)
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = []
        }
      } else {
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = {}
        }
      }
      obj = obj[myArray[i]]
    }
    obj[myArray[len - 1]] = docRaw[keyRaw]
  }
  return doc
}

export async function createBusinessDraft(businessDraftDoc, uid) {
  console.log(businessDraftDoc)
  const docData = convertDotNotation(businessDraftDoc, "form")

  docData.phone.secondaries = docData.phone.secondary?.length > 0 ? [docData.phone.secondary] : []
  docData.address.geo.hash = ""
  delete docData.phone.secondary

  docData.ownerId = uid
  docData.draft_status = {
    id: "waiting",
    date_create: serverTimestamp(),
  }
  console.log(docData)

  const newDocRef = collection(db, businessDraftPath)
  await addDoc(newDocRef, docData)
}

export async function getBusinessDraft(uid) {
  const c = collection(db, businessDraftPath)
  const q = query(c, where("ownerId", "==", uid))
  const qs = await getDocs(q)

  if (qs.empty) throw new CodeError(`Documento con ownerId "${uid}" non esiste`, CodeError.notFound)
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
    // notFound: false,
  }
}

export function getBusinessDraftSnapshot(uid, callback) {
  const c = collection(db, businessDraftPath)
  const q = query(c, where("ownerId", "==", uid))

  return onSnapshot(q, (qs) => {
    if (qs.empty) {
      callback({})
    } else {
      callback({
        id: qs.docs[0].id,
        data: qs.docs[0].data(),
        // notFound: false,
      })
    }
  })
}

// when useBusinessDraftDoc null, loading
// when useBusinessDraftDoc {}, not-found or other errors
// when useBusinessDraftDoc .data, document exist and ready
export function useBusinessDraftDocSingle(uid) {
  const [draftDoc, setDraftDoc] = useState()

  useEffect(() => {
    getBusinessDraft(uid)
      .then((fbDoc) => setDraftDoc(fbDoc))
      .catch((e) => {
        if (e instanceof CodeError && e.isNotFound()) {
        }
        setDraftDoc({})
      })
  }, [uid])

  return draftDoc
}

export function useBusinessDraftDoc(uid) {
  // console.log("useBusinessDraft")
  const [draftDoc, setDraftDoc] = useState()

  useEffect(() => {
    const unsub = getBusinessDraftSnapshot(uid, (doc) => {
      console.log("doc")
      console.log(doc)
      setDraftDoc(doc)
    })
    return () => {
      unsub()
    }
  }, [uid])

  return draftDoc
}

export async function updateBusinessDraft(docId, businessDraft) {
  console.log("updating doc: " + docId)

  const docData = businessDraft

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
  docData["draft_status.id"] = "waiting"
  docData["draft_status.date_edit"] = serverTimestamp()
  console.log(docData)
  const docRef = doc(db, businessDraftPath, docId)
  return updateDoc(docRef, docData, { merge: true })
}
