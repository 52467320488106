import { matchPath, Outlet, useLocation } from "react-router-dom"
import Footer from "common/Footer"
import Header from "common/Header"

export default function Root() {
  const { pathname } = useLocation()
  let footerClassName
  if (matchPath("", pathname)) footerClassName = "bg-light"
  return (
    <>
      <Header />
      <div className="bg-light">
        <Outlet />
      </div>
      <Footer className={footerClassName} />
    </>
  )
}
