import { Form } from "react-bootstrap"
import { validateLength } from "scripts/Validation"

export const businessFields = {
  type: { error: "È necessario selezionare la categoria della tua attività" },
  title: { label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
  "address.street": { label: "Via", isAddressField: true, validate: true, error: " " },
  "address.house_number": { label: "Num. civico", isAddressField: true, validate: true, error: " " },
  "address.city": { label: "Comune", isAddressField: true, validate: true, error: " " },
  "address.district": { label: "Provincia", isAddressField: true, validate: true, error: " " },
  "address.postal_code": {
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
  "phone.primary": {
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
  "owner.name": { label: "Nome", validate: true, error: "Campo obbligatorio" },
  "owner.surname": { label: "Cognome", validate: true, error: "Campo obbligatorio" },
  "owner.phone": { label: "Numero di telefono", type: "tel", validate: true, error: "Campo obbligatorio" },
}

export function buildErrors(obj, fields) {
  let locErrors = {}
  Object.keys(fields).forEach((key) => {
    if (fields[key].validate) {
      let error = buildError(obj[key], fields[key])
      if (error) locErrors[key] = error
    }
  })
  return locErrors
}

function buildError(val, field) {
  let error
  if (!validateLength(val, field.minLength, true)) error = field.error
  return error
}

export function InputGroupBox({ id, as, value, onChange, errors, fields, innerRef, ...props }) {
  return (
    <Form.Group key={id} as={as} controlId={id}>
      <Form.Label>{fields[id].label}</Form.Label>
      <Form.Control
        {...props}
        ref={innerRef}
        type={fields[id].type ?? "text"}
        value={value || ""}
        onChange={onChange}
        isInvalid={!!errors[id]}
      />
      {errors[id] !== " " && <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>}
    </Form.Group>
  )
}

export const businessInfoFieldsNew = {
  type: { id: "type", error: "È necessario selezionare la categoria della tua attività" },
  title: { id: "title", label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
  address_street: { id: "address.street", label: "Via", isAddressField: true, validate: true, error: " " },
  address_houseNumber: {
    id: "address.house_number",
    label: "Num. civico",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  address_city: { id: "address.city", label: "Comune", isAddressField: true, validate: true, error: " " },
  address_district: { id: "address.district", label: "Provincia", isAddressField: true, validate: true, error: " " },
  address_postalCode: {
    id: "address.postal_code",
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
  "phone.primary": {
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
  "owner.name": { label: "Nome", validate: true, error: "Campo obbligatorio" },
  "owner.surname": { label: "Cognome", validate: true, error: "Campo obbligatorio" },
  "owner.phone": { label: "Numero di telefono", type: "tel", validate: true, error: "Campo obbligatorio" },
}

export const businessFieldNames = {
  type: "type",
  title: "title",
  phone_primary: "phone.primary",
  owner_name: "owner.name",
  owner_surname: "owner.surname",
  owner_phone: "owner.phone",
  address_street: "address.street",
  address_house_number: "address.house_number",
  address_city: "address.city",
  address_district: "address.district",
  address_postal_code: "address.postal_code",
  geo_location: "address.geo.location",
  geo_lat: "address.geo.location.lat",
  geo_lng: "address.geo.location.lng",
}

export const businessInfoFields = {
  [businessFieldNames.type]: { error: "È necessario selezionare la categoria della tua attività" },
  [businessFieldNames.title]: { label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
}

export const businessContactsFields = {
  [businessFieldNames.phone_primary]: {
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
}
export const businessOwnerInfoFields = {
  [businessFieldNames.owner_name]: { label: "Nome", validate: true, error: "Campo obbligatorio" },
  [businessFieldNames.owner_surname]: { label: "Cognome", validate: true, error: "Campo obbligatorio" },
  [businessFieldNames.owner_phone]: {
    label: "Numero di telefono",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
}

export const businessAddressFields = {
  [businessFieldNames.address_street]: { label: "Via", isAddressField: true, validate: true, error: " " },
  [businessFieldNames.address_house_number]: { label: "Num. civico", isAddressField: true, validate: true, error: " " },
  [businessFieldNames.address_city]: { label: "Comune", isAddressField: true, validate: true, error: " " },
  [businessFieldNames.address_district]: { label: "Provincia", isAddressField: true, validate: true, error: " " },
  [businessFieldNames.address_postal_code]: {
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
}

export const FormState = {
  idle: 0,
  editing: 1,
  saving: 2,
}

export function checkNoErrors(errors) {
  if (Object.keys(errors).length === 0) {
    return true
  } else {
    return false
  }
}

export function setField(field, value, setForm) {
  setForm((currentForm) => {
    return {
      ...currentForm,
      [field]: value,
    }
  })
}
