import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"

import {
  buildErrors,
  businessContactsFields,
  businessFieldNames,
  businessInfoFields,
  businessOwnerInfoFields,
  checkNoErrors,
  FormState,
} from "routes/business/BusinessForm"
import { updateBusinessDraft } from "scripts/private"
import EditCancelSaveButtons from "routes/business/edit/EditCancelSaveButtons"
import EditBusInfoForm from "./EditBusInfoForm"

import EditBusContactsForm from "./EditBusContactsForm"
import SpinnerCentered from "common/SpinnerCentered"
import EditBusOwnerInfoForm from "./EditBusOwnerInfoForm"
import EditBusAddressForm from "./EditBusAddressForm"

const fieldNames = businessFieldNames

function initForm(data, section) {
  console.log("init form")
  switch (section) {
    case "info":
      return {
        [fieldNames.title]: data.title,
      }
    case "contacts":
      return {
        [fieldNames.phone_primary]: data.phone.primary,
      }
    case "owner_info":
      return {
        [fieldNames.owner_name]: data.owner.name,
        [fieldNames.owner_surname]: data.owner.surname,
        [fieldNames.owner_phone]: data.owner.phone,
      }
    case "address":
      return {
        [fieldNames.address_city]: data.address.city,
        [fieldNames.address_district]: data.address.district,
        [fieldNames.address_house_number]: data.address.house_number,
        [fieldNames.address_postal_code]: data.address.postal_code,
        [fieldNames.address_street]: data.address.street,
        [fieldNames.geo_location]: data.address.geo.location,
        // [fieldNames.geo_lat]: data.address.geo.location.lat,
        // [fieldNames.geo_lng]: data.address.geo.location.lng,
      }
    default:
      return {}
  }
}

function getFields(section) {
  console.log("getFiels")
  switch (section) {
    case "info":
      return businessInfoFields
    case "contacts":
      return businessContactsFields
    case "owner_info":
      return businessOwnerInfoFields
    default:
      return {}
  }
}

export default function EditWrapper({ section, ...props }) {
  console.log("rerender")

  const [formStatus, setFormStatus, doc, setSection] = useOutletContext()
  const [fields, setFields] = useState(() => getFields(section))

  const [form, setForm] = useState(() => initForm(doc.data, section))
  console.log(form)
  // const [errors, setErrors] = useState(() => buildErrors(form, fields))

  useEffect(() => {
    console.log("trigger doc, section")
    setForm(initForm(doc.data, section))
    setFields(getFields(section))
  }, [doc, section])

  useEffect(() => {
    console.log("trigger form, fields")
  }, [form, fields])

  let errors = buildErrors(form, fields)
  useEffect(() => {
    setSection(section)
  })

  if (!form) {
    return <SpinnerCentered />
  }

  const subProps = {
    form: form,
    errors: errors,
    formStatus: formStatus,
    setForm: setForm,
    fields: fields,
  }

  return (
    <div>
      {section === "info" && <EditBusInfoForm {...subProps} />}
      {section === "contacts" && <EditBusContactsForm {...subProps} />}
      {section === "owner_info" && <EditBusOwnerInfoForm {...subProps} />}
      {section === "address" && <EditBusAddressForm {...subProps} />}
      <EditCancelSaveButtons
        status={formStatus}
        onCancel={() => {
          setForm(initForm(doc.data, section))
          setFormStatus(FormState.idle)
        }}
        onEdit={() => setFormStatus(FormState.editing)}
        onSave={async () => {
          if (checkNoErrors(errors)) {
            await updateBusinessDraft(doc.id, form)
            setFormStatus(FormState.idle)
          }
        }}
      />
    </div>
  )
}
