import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FormState } from "../BusinessForm"

export default function EditCancelSaveButtons({ status, onEdit, onCancel, onSave }) {
  return (
    <Row className="justify-content-end mt-3">
      <Col xs="auto">
        {status === FormState.idle && (
          <Button variant="primary" className="ms-2" onClick={onEdit}>
            Modifica
          </Button>
        )}
        {status === FormState.editing && (
          <>
            <Button variant="secondary" onClick={onCancel}>
              Annulla
            </Button>
            <Button variant="primary" className="ms-2" onClick={onSave}>
              Salva
            </Button>
          </>
        )}
      </Col>
    </Row>
  )
}
