import { addDoc, collection, query, where, getDocs, doc, setDoc, serverTimestamp } from "firebase/firestore"
import { db } from "scripts/FirebaseApp"

const businessPublicPath = "business"

export async function getBusinessPublic(urlId) {
  const c = collection(db, businessPublicPath)
  const q = query(c, where("urlId", "==", urlId))
  const qs = await getDocs(q)
  if (qs.empty) throw Error(`Documento con urlId "${urlId}" non esiste`)
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
    // updateTime: qs.docs[0].updateTime,
  }
}

export async function getBusinessPublicNew(urlId) {
  const pubBusRef = collection(db, businessPublicPath)
  const q = query(pubBusRef, where("urlId", "==", urlId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

export async function subscribeNewsletter(docData) {
  // email as document id
  docData.date = serverTimestamp()
  const newDocRef = doc(db, "public_forms", "newsletter", "app_launch", docData.email)
  return setDoc(newDocRef, docData)
}

export async function submitContactForm(docData) {
  // autogenerated id
  docData.date = serverTimestamp()
  const colRef = collection(db, "public_forms", "messages", "contacts")
  return addDoc(colRef, docData)
}
