import { render } from "@testing-library/react"
import React, { useState } from "react"
import { Link, Navigate } from "react-router-dom"

const delay = (seconds) => new Promise((res) => setTimeout(res, seconds * 1000))

export default function AwaitNavigate({ seconds, children, to, ...props }) {
  const [status, setStatus] = useState(false)

  console.log("init")
  delay(seconds).then(() => {
    setStatus(true)
  })
  return status ? (
    <Navigate to={to} {...props} />
  ) : (
    <>
      <Link to={to}>{children}</Link>
    </>
  )
}
