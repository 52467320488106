import { firebaseApp } from "scripts/FirebaseApp"
import {
  getAuth,
  signOut,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth"

export const auth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider()

export function firebaseCreateAccountEmail(email, password) {
  return createUserWithEmailAndPassword(auth, email, password)
}

export async function firebaseLoginEmail(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
}

export function firebaseLogout() {
  return signOut(auth)
}
export function firebaseCheckUser(callback) {
  return onAuthStateChanged(auth, callback)
}

export function firebaseLoginGooglePopup() {
  return signInWithPopup(auth, provider)
}

export function firebaseResetPassword(email) {
  return sendPasswordResetEmail(auth, email)
}
