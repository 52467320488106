import React from "react"

import Home from "routes/Home"

import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from "react-router-dom"
import Root from "routes/Root"
import ErrorPage from "routes/ErrorPage"
import Dashboard from "routes/business/Dashboard"
import About from "routes/About"
// import BusinessHome from "routes/BusinessHome"

import PrivateAuthRoute from "components/PrivateAuthRoute"

import BusinessPublic from "BusinessPublic"
import BusinessRoot from "routes/business/BusinessRoot"
import BusinessHome from "routes/business/BusinessHome"
import RecoveryPassword from "components/RecoveryPassword"

import Contacts from "routes/Contacts"
import Privacy from "routes/legal/Privacy"
import NewsletterApp from "routes/newsletter/NewsletterApp"
import TitleWrapper from "TitleWrapper"
import SignUp from "routes/business/SignUp"
import LogIn from "routes/business/LogIn"

import { getBusinessPublic } from "scripts/public"

import MapNew3 from "common/MapNew3"
import AddBusiness from "routes/business/AddBusiness"
import EditBusiness from "routes/business/EditBusiness"
import EditBusContacts from "routes/business/edit/EditBusContacts"
import EditBusOwnerInfo from "routes/business/edit/EditBusOwnerInfo"
import EditWrapper from "routes/business/edit/EditWrapper"

//https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
const routes = [
  {
    path: "/",
    element: (
      <>
        <ScrollRestoration />
        <TitleWrapper>
          <Outlet />
        </TitleWrapper>
      </>
    ),
    children: [
      {
        path: "",
        element: (
          <>
            <Root />
          </>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: ":businessId",
            element: <BusinessPublic />,
            loader: async ({ params }) => {
              try {
                return await getBusinessPublic(params.businessId)
              } catch (e) {
                return false
              }
            },
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "contacts",
            element: <Contacts />,
          },
          {
            path: "legal/privacy",
            element: <Privacy />,
          },
          {
            path: "newsletter/app",
            element: <NewsletterApp />,
          },
        ],
      },
      {
        path: "prova/",
        element: <MapNew3 />,
      },
      {
        path: "business/",
        element: (
          <BusinessRoot padding={false}>
            <BusinessHome />
          </BusinessRoot>
        ),
      },
      {
        path: "business",
        element: (
          <>
            <BusinessRoot padding={true}>
              <Outlet />
            </BusinessRoot>
          </>
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateAuthRoute>
                <Outlet />
              </PrivateAuthRoute>
            ),
            children: [
              {
                path: "dashboard",
                element: <Dashboard />,
              },
              {
                path: "add_business",
                element: <AddBusiness />,
              },
              {
                path: "edit_business",
                element: <EditBusiness />,
                children: [
                  {
                    path: "info",
                    // element: <EditBusInfo />,
                    element: <EditWrapper section="info" />,
                  },
                  {
                    path: "contacts",
                    element: <EditWrapper section="contacts" />,
                  },
                  {
                    path: "address",
                    element: <EditWrapper section="address" />,
                  },
                  {
                    path: "owner_info",
                    element: <EditWrapper section="owner_info" />,
                  },
                  // {
                  //   path: "owner_info",
                  //   element: <EditBusOwnerInfo />,
                  // },
                  // {
                  //   path: "pro",
                  //   element: <EditWrapper section="info" />,
                  // },
                ],
              },
            ],
          },
          {
            path: "signup",
            element: <SignUp />,
          },
          {
            path: "login",
            element: <LogIn redirectToDashboadOnLogin={true} />,
          },
          {
            path: "recovery-password",
            element: <RecoveryPassword />,
          },
        ],
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
  // <BrowserRouter>

  /* <Routes>
        <Route
          path="/"
          element={
            <>
              <Root />
            </>
          }
        >
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="legal/privacy" element={<Privacy />} />
          <Route path="newsletter/app" element={<NewsletterApp />} />
        </Route>

        <Route path="/business" element={<BusinessRoot padding={false} />} title="/business false">
          <Route path="" element={<BusinessHome />} title="child" />
        </Route>
        <Route path="/business" element={<BusinessRoot padding={true} />} title="/business true">
          <Route
            path="dashboard"
            element={
              <PrivateAuthRoute>
                <Dashboard />
              </PrivateAuthRoute>
            }
          />
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<LogIn />} title="ehi" />
          <Route path="recovery-password" element={<RecoveryPassword />} />
        </Route>
        <Route path="/:businessId" element={<BusinessPublic />} />
      </Routes>
    </BrowserRouter> */
}
