import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap"
import { useAuth } from "context/AuthContext"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import SpinnerCentered from "common/SpinnerCentered"
import { useBusinessDraftDoc } from "scripts/private"

export default function Dashboard() {
  const { currentUser } = useAuth()
  const draftDoc = useBusinessDraftDoc(currentUser.uid)

  function renderCard(busDoc) {
    let content

    if (busDoc.data) {
      const docData = busDoc.data
      const isOnline = !!docData.urlId
      const waitCreate = !isOnline && docData.draft_status.id === "waiting"
      const waitUpdate = isOnline && docData.draft_status.id === "waiting"
      content = (
        <>
          <Card.Title>{docData.title}</Card.Title>
          <Card.Subtitle as="p" className="mb-2">
            {isOnline && (
              <Badge bg="success" id="d_page_link" className="me-2">
                Online
              </Badge>
            )}
            {waitCreate && (
              <Badge bg="danger" className="me-2">
                In attesa di approvazione
              </Badge>
            )}
            {waitUpdate && (
              <Badge bg="warning" text="dark" className="me-2">
                Modifiche in attesta di approvazione
              </Badge>
            )}
          </Card.Subtitle>

          {docData.urlId && (
            <Card.Text>
              <Link to={`/${docData.urlId}`} rel="noopener noreferrer">
                {`www.joppys.pet/${docData.urlId}`}
              </Link>
            </Card.Text>
          )}
          {isOnline && (
            <Card.Text>
              <i className="bi bi-check-lg me-2"></i>La tua pagina è visibile sulla mappa di Joppys e nei risultati di
              ricerca.
            </Card.Text>
          )}
          <Card.Text>
            <i className="bi bi-info-circle me-2"></i>Nelle prossime ore lo staff di Joppys verificherà le informazioni
            che ci hai fornito.
          </Card.Text>

          <Button to="/business/edit_business" as={Link}>
            <i className="bi bi-pen-fill me-2"></i>Modifica
          </Button>
        </>
      )
    } else {
      content = (
        <>
          <Card.Text>Crea la pagina della tua attività.</Card.Text>
          <Button to="/business/add_business" as={Link}>
            <i className="bi bi-plus-lg me-2"></i>Crea nuova Pagina
          </Button>
        </>
      )
    }

    return (
      <Card>
        <Card.Header as="h5">La tua pagina</Card.Header>
        <Card.Body>{content}</Card.Body>
      </Card>
    )
  }

  return !draftDoc ? (
    <SpinnerCentered />
  ) : (
    currentUser && (
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <p>
                  Ciao {currentUser.displayName}! ({currentUser.email})
                </p>
              </Col>
              <Col md={5}>{renderCard(draftDoc)}</Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    )
  )
}
