import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function Footer(props) {
  return (
    <Container fluid className={"border-top " + props.className}>
      <div className="container pt-5 ">
        <div className="row">
          <div className="col-12 col-md-5 order-last order-md-first mb-3">
            <div className="row">
              <div className="col-12 col-sm-8 col-md-12">
                <h5>Joppys, make your pet's life easier</h5>
                <div className="p my-4">
                  Joppys la piattaforma digitale che raccoglie i servizi per te e il tuo animale.
                  <br />
                  Annota. Cerca. Contatta.
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-12">
                <div className="h5">Seguici sui social</div>
                <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
                  <symbol id="facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </symbol>
                  <symbol id="linkedin" viewBox="2.06 2 19.88 20">
                    <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" />
                  </symbol>
                  <symbol id="instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </symbol>
                  <symbol id="twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </symbol>
                </svg>
                <ul className="list-unstyled d-flex my-4">
                  <li className="ms-0">
                    <a
                      className="link-dark"
                      href="https://www.facebook.com/JoppysPet"
                      rel="noopener noreferrer"
                      alt="Pagina Facebook di Joppys"
                      target="_blank"
                    >
                      <svg className="bi" width="24" height="24">
                        <use xlinkHref="#facebook" />
                      </svg>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://www.instagram.com/JoppysPet/"
                      rel="noopener noreferrer"
                      alt="Pagina Instagram di Joppys"
                      target="_blank"
                    >
                      <svg className="bi" width="24" height="24">
                        <use xlinkHref="#instagram" />
                      </svg>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://www.linkedin.com/company/Joppys"
                      rel="noopener noreferrer"
                      alt="Pagina Linkedin di Joppys"
                      target="_blank"
                    >
                      <svg className="bi" width="24" height="24">
                        <use xlinkHref="#linkedin" />
                      </svg>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://twitter.com/JoppysPet"
                      rel="noopener noreferrer"
                      alt="Pagina Linkedin di Joppys"
                      target="_blank"
                    >
                      <svg className="bi" width="24" height="24">
                        <use xlinkHref="#twitter" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-2 offset-md-1  mb-3">
            <div className="h5 pb-2">Contatti</div>
            <ul className="nav flex-column">
              {/* <li className="nav-item mb-2">
                <a href="/help.html" className="nav-link p-0 text-muted">
                  Assistenza clienti
                </a>
              </li> */}
              <li className="nav-item mb-2">
                <Link to="/contacts" className="nav-link p-0 text-muted">
                  Contattaci
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-md-2 mb-3">
            <div className="h5 pb-2">Scarica App</div>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/newsletter/app" className="nav-link p-0 text-muted">
                  Android
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/newsletter/app" className="nav-link p-0 text-muted">
                  iOS
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-md-2 mb-3">
            <div className="h5 pb-2">Joppys</div>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/about" className="nav-link p-0 text-muted">
                  Chi siamo
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="legal/privacy" className="nav-link p-0 text-muted">
                  Termini e condizioni
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="legal/privacy" className="nav-link p-0 text-muted">
                  Informativa privacy e Cookie policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          <p>
            &copy; 2022 Joppys s.r.l.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://startup.registroimprese.it/isin/dettaglioStartup?10&id=KXU3bCJQBfS92AJOXqJVKg%2BWN%2BvoDCorxKwjUwvhow%3D%3D"
            >
              Startup innovativa
            </a>
          </p>
        </div>
      </div>
    </Container>
  )
}
