import React from "react"
import { Spinner } from "react-bootstrap"

export default function SpinnerCentered() {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Spinner></Spinner>
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}
