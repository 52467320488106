import { Outlet, useLoaderData } from "react-router-dom"
import React from "react"
import Footer from "common/Footer"
import BusinessHeader from "routes/business/BusinessHeader"
import { Container } from "react-bootstrap"
import { AuthContextProvider } from "context/AuthContext"

export default function BusinessRoot(props) {
  const outlet = props?.padding ? (
    <Container fluid className="bg-light py-5">
      {props.children}
    </Container>
  ) : (
    <>{props.children}</>
  )
  return (
    <>
      <AuthContextProvider>
        <BusinessHeader />
        {outlet}
        <Footer />
      </AuthContextProvider>
    </>
  )
}
