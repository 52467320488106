import React, { useState, useContext } from "react"
import { useEffect } from "react"
import {
  firebaseCreateAccountEmail,
  firebaseCheckUser,
  firebaseLogout,
  firebaseLoginEmail,
  firebaseLoginGooglePopup,
  firebaseResetPassword,
} from "scripts/Auth"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return firebaseCreateAccountEmail(email, password)
  }

  function login(email, password) {
    return firebaseLoginEmail(email, password)
  }
  function logout() {
    return firebaseLogout()
  }
  function loginGoogle() {
    return firebaseLoginGooglePopup()
  }

  function resetPassword(email) {
    return firebaseResetPassword(email)
  }

  useEffect(() => {
    const unsubscribe = firebaseCheckUser((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    signup,
    login,
    logout,
    loginGoogle,
    resetPassword,
  }
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
