import React from "react"

export default function JoppysImage(prop) {
  const { content, ...other } = prop
  // console.log(prop)
  return <img src={content.src} alt={content.alt} {...other} />
}

const IMG_PATH = "img/"
export const iLogo = { src: require(IMG_PATH + "joppys_logo.png"), alt: "Joppys" }

// home
const HOME_PATH = IMG_PATH + "home/"
export const icHome = {
  PlayStoreBadge: { src: require(HOME_PATH + "it_playstore_badge.png"), alt: "Disponibile su Google Play" },
  AppStoreBadges: { src: require(HOME_PATH + "it_appstore_badge.svg"), alt: "Disponibile su App Store" },
  JoppysOwner: {
    src: require(HOME_PATH + "joppys_owner.png"),
    alt: "La app di Joppys per il tuo smartphone",
  },
  JoppysBusiness: { src: require(HOME_PATH + "joppys_business.png"), alt: "Joppys per il tuo business" },
  WhyJoppysArchive: { src: require(HOME_PATH + "WhyJoppys_Archive.svg"), alt: "" },
  WhyJoppysBusCalendar: { src: require(HOME_PATH + "WhyJoppys_Bus_Calendar.svg"), alt: "" },
  WhyJoppysBusEasy: { src: require(HOME_PATH + "WhyJoppys_Bus_Easy.svg"), alt: "" },
  WhyJoppysBusLoyalty: { src: require(HOME_PATH + "WhyJoppys_Bus_Loyalty.svg"), alt: "" },
  WhyJoppysBusPagina: { src: require(HOME_PATH + "WhyJoppys_Bus_Pagina.svg"), alt: "" },
  WhyJoppysBusUpdatedInfo: { src: require(HOME_PATH + "WhyJoppys_Bus_Updated_Info.svg"), alt: "" },
  WhyJoppysNews: { src: require(HOME_PATH + "WhyJoppys_News.svg"), alt: "" },
  WhyJoppysPets: { src: require(HOME_PATH + "WhyJoppys_Pets.svg"), alt: "" },
  WhyJoppysSearch: { src: require(HOME_PATH + "WhyJoppys_Search.svg"), alt: "" },
  WhyJoppysTips: { src: require(HOME_PATH + "WhyJoppys_Tips.svg"), alt: "" },
}

const ABOUT_PATH = IMG_PATH + "about/"
export const icAbout = {
  Team: { src: require(ABOUT_PATH + "joppys_team_web.webp"), alt: "Il team di Joppys" },
}

const BUSINESS_PATH = IMG_PATH + "business/"
export const iLogoBusiness = { src: require(BUSINESS_PATH + "joppys_logo_business.png"), alt: "Joppys" }
export const iMapPin = { src: require(BUSINESS_PATH + "google_maps_pin.svg"), alt: "" }
