import React from "react"
import { Card, Container } from "react-bootstrap"

export default function Privacy() {
  return (
    <Container className="py-5">
      <Card className="rounded-4 p-5" style={{ textAlign: "justify", textJustify: "inter-word" }}>
        <h3>Informativa privacy e Cookie policy</h3>
        <p>
          La presente informativa sulla privacy e sull'uso dei cookie (l'"<strong>Informativa sulla Privacy</strong>")
          descrive in dettaglio i dati utilizzati sul sito www.joppys.pet (il "<strong>Sito</strong>"), sul software
          dell'applicazione mobile Joppys e sul portale web Joppys Business (gestione pagina attivit&agrave;), la prima
          scaricabile da iTunes e Google Play (le "<strong>App</strong>"), sui siti personalizzabili di Joppys Business
          ospitati su indirizzi di sottodominio univoci su joppys.pet (i "<strong>Siti Partner</strong>") <br />
          Il titolare del trattamento dei tuoi dati &egrave; <strong>Joppys s.r.l.</strong>: (sede legale: Via di
          Colognora, 54a, 55012) ("Joppys"). &Egrave; possibile contattare Joppys scrivendo all'indirizzo: Via di
          Colognora, 54A, 55012, Lucca, Italia oppure inviando una email a: <u>info@joppys.pet</u>.<br />
          Joppys S.r.l. (la "<em>Societ&agrave;</em>") gestisce dati personali dell&rsquo;utente anche in qualit&agrave;
          di responsabile del trattamento dei dati personali, in quanto necessari per le finalit&agrave; descritte nella
          presente informativa. Le Societ&agrave; con cui sono condivisi i dati sono elencate nella sezione della
          presente Informativa sulla Privacy denominata "Condivisione dei dati personali con terzi", unitamente al
          dettaglio dei relativi contatti.
        </p>
        <p>
          Joppys si impegna per proteggere la privacy dei suoi utenti e clienti. Scopo della presente Informativa sulla
          Privacy &egrave; informare l'utente del modo in cui Joppys raccoglie, definisce e utilizza i dati
          identificativi, quali nome, indirizzo email, indirizzo, altri dati di contatto o identificativi online e altre
          informazioni fornite dall'utente durante l'utilizzo della Piattaforma (i "<strong>Dati Personali</strong>"),
          nonch&eacute; metterlo al corrente di quali Cookie utilizza. Si consiglia di leggere attentamente la presente
          Informativa sulla Privacy.
        </p>
        <h5>Quali informazioni vengono raccolte da Joppys?</h5>
        <p>
          Quando l'utente visita e/o utilizza la Piattaforma oppure contatta Joppys, quest'ultima potrebbe raccogliere
          le seguenti informazioni:
        </p>

        <ul>
          <li>
            Dati Personali che l'utente ha scelto di condividere con Joppys quando registra un account o si iscrive a
            email, newsletter e avvisi e che fornisce a Joppys quando utilizza i suoi servizi, comprese le informazioni
            inserite sulla piattaforma appuntamenti e incluse nei commenti, nelle recensioni o nelle risposte ai
            sondaggi dell'utente. Durante il processo di prenotazione di un appuntamento o di invio di una recensione,
            l'utente potrebbe volontariamente fornire a Joppys dati personali sensibili (riguardanti, ad esempio, la sua
            salute o etnia), se relativi al servizio che sta richiedendo o recensendo.
          </li>
          <li>
            Le informazioni personali che condividete con noi come parte di una candidatura di lavoro presso Joppys,
            inviate direttamente tramite siti web o indirettamente, incluso ma non limitato a, tramite un'agenzia di
            reclutamento, una candidatura spontanea o una piattaforma di reclutamento di terze parti. Nel corso della
            presentazione di una candidatura, l'utente pu&ograve; scegliere di fornirci volontariamente dati personali
            sensibili relativi all'opportunit&agrave; di apportare ragionevoli adeguamenti nel processo di candidatura
            o, successivamente, nel caso in cui venga stabilito un rapporto di lavoro. Tuttavia, non dovete condividere
            con noi dati personali sensibili che non sarebbero necessari per apportare ragionevoli adeguamenti, ad
            esempio opinioni politiche, convinzioni religiose o informazioni specifiche sul vostro stato di salute.
          </li>
          <li>
            Quando l'utente utilizza il sistema di pagamento online di Joppys, quest'ultima potrebbe conservare (in
            prima persona o tramite il suo gestore dei pagamenti designato) i dati della carta di credito o di debito
            dell'utente in forma criptata e sicura.
          </li>
          <li>
            Qualora l'utente decida di comunicare con Joppys (ad esempio, tramite la Piattaforma o via email, telefono,
            SMS o social media), la Societ&agrave; registrer&agrave; il fatto che l'utente l'abbia contattata e
            potr&agrave; conservare il contenuto della comunicazione, assieme ai dati di contatto e alle risposte
            fornite.
          </li>
          <li>
            Le informazioni sulle visite dell'utente alla Piattaforma e il suo utilizzo, come quelle sul dispositivo e
            sul browser usati dall'utente, l'indirizzo IP e la posizione, l'identificatore IDFA, la data e l'ora della
            visita, la durata della visita, il sito di rinvio, i percorsi di navigazione sul sito durante la visita e le
            interazioni dell'utente sulla Piattaforma, inclusi i professionisti e i trattamenti cui &egrave;
            interessato, possono essere associati da Joppys all'account Joppys dell'utente. Per ulteriori informazioni
            sulle finalit&agrave; della raccolta e dell'utilizzo di tali informazioni, leggere la sezione della presente
            Informativa sulla Privacy dedicata all'uso dei cookie.
          </li>
        </ul>
        <p>
          &Egrave; importante che tutti i Dati Personali che l'utente fornisce al momento della registrazione o in altro
          modo durante l'utilizzo della Piattaforma siano corretti e accurati. Ci&ograve; significa, a titolo puramente
          esemplificativo, garantire che Joppys disponga sempre dei dati di contatto dell'utente, incluso l'indirizzo
          email.
        </p>
        <p>
          Qualora l'utente preveda di inviare a Joppys i Dati Personali di un'altra persona, ad esempio mentre effettua
          una prenotazione per conto della stessa, dovrebbe fornire alla Societ&agrave; i dati di tale soggetto terzo
          soltanto con il consenso dell'interessato e dopo che quest'ultimo abbia avuto accesso alle informazioni sulle
          modalit&agrave; di utilizzo dei dati, incluse le finalit&agrave; indicate nella presente Informativa sulla
          Privacy.
        </p>
        <h5>Protezione dei dati personali</h5>
        <p>
          La trasmissione delle informazioni via internet non &egrave; completamente sicura. Per questo motivo,
          nonostante Joppys faccia del suo meglio per proteggere i Dati Personali dell'utente, non pu&ograve; garantire
          la sicurezza dei dati trasmessi alla sua Piattaforma. Qualunque trasmissione viene effettuata a rischio
          dell'utente. Una volta che ha ricevuto i Dati Personali dell'utente, Joppys si avvarr&agrave; di procedure
          rigorose e funzioni di sicurezza per il trattamento degli stessi.
        </p>
        <h5>Come vengono utilizzati i dati personali dell'utente?</h5>
        <p>
          In generale, Joppys utilizza i Dati Personali per fornire all'utente i servizi e prodotti richiesti, elaborare
          pagamenti, offrire servizi clienti, mostrare contenuti e pubblicit&agrave; che ritiene di interesse per
          l'utente, effettuare ricerche sui clienti, inviare email commerciali e promozionali e informare l'utente di
          importanti modifiche della Piattaforma.
        </p>
        <p>Joppys utilizza inoltre i Dati Personali dell'utente per le seguenti finalit&agrave;:</p>
        <ul>
          <li>
            Adempiere un contratto o porre in essere azioni relative a esso. Questo &egrave; importante quando l'utente
            richiede un servizio attraverso Joppys. Tali adempimenti/azioni includono:
            <ul>
              <li>
                Inviare all'utente informazioni sui servizi richiesti (come promemoria degli appuntamenti) via email,
                SMS e notifiche push.
              </li>
              <li>Facilitare le prenotazioni ed effettuare pagamenti.</li>
              <li>Rispondere a domande e dubbi.</li>
              <li>Gestire l'account.</li>
            </ul>
          </li>
          <li>
            Gestire l'attivit&agrave; e perseguire i propri interessi legittimi, come previsto da Joppys, in
            particolare:
            <br />
            <ul>
              <li>
                Mantenere sicuri la Piattaforma e i sistemi e prevenire frodi, problemi di sicurezza e altri crimini.
              </li>
              <li>
                Verificare il rispetto di termini e condizioni e accertare, esercitare e difendersi da pretese
                giuridiche.
              </li>
              <li>
                Informare l'utente dei nuovi servizi di Joppys e di offerte speciali che la Societ&agrave; ritiene
                possano essere interessanti per lo stesso, ad esempio via email, e mostrando pubblicit&agrave; su siti
                web di soggetti terzi quali Facebook (laddove non sia necessario il consenso).
              </li>
              <li>
                Adattare e personalizzare annunci commerciali e pubblicit&agrave; sulla base delle informazioni fornite
                da te e altri utenti e del suo utilizzo della Piattaforma, dei prodotti e servizi di Joppys e di altri
                siti (per ulteriori informazioni, leggere la sezione della presente Informativa sulla Privacy dedicata
                all'uso dei cookie).
              </li>
              <li>
                Aiutare a monitorare, migliorare e proteggere la Piattaforma, nonch&eacute; personalizzare la
                Piattaforma, i prodotti e servizi di Joppys (e quelli dei suoi partner) rispetto all'utente, sulla base
                delle informazioni fornite dallo stesso e del suo utilizzo della Piattaforma, dei prodotti e servizi di
                Joppys e di altri siti (per ulteriori informazioni, leggere la sezione della presente Informativa sulla
                Privacy dedicata all'uso dei cookie).
              </li>
              <li>
                Effettuare indagini e rispondere a eventuali domande o reclami ricevuti dall'utente o da altri
                relativamente alla Piattaforma ovvero ai prodotti e servizi di Joppys.
              </li>
              <li>
                Condurre ricerche interne e analisi in modo da poter vedere come vengono utilizzati la Piattaforma,
                nonch&eacute; i prodotti e servizi di Joppys, e come sta andando l'attivit&agrave;.
              </li>
              <li>Realizzare ricerche di mercato e sondaggi tra i consumatori.</li>
              <li>
                Raccogliere e pubblicare recensioni di prodotti o servizi offerti dai Partner di Joppys e utilizzarle
                per campagne pubblicitarie.
              </li>
              <li>
                Gestire le candidature per una posizione in Joppys, sia direttamente che indirettamente, anche per
                esaminare la vostra idoneit&agrave; per il ruolo, il curriculum vitae e le competenze professionali, per
                programmare colloqui e valutazioni, per contattare i referenti e, in caso di successo, per offrirvi un
                lavoro e stabilire un rapporto di lavoro con voi. Le informazioni da voi fornite saranno conservate per
                un periodo di 12 mesi e potranno essere consultate nel caso in cui facciate future candidature per
                lavorare presso Joppys. L'utente pu&ograve; optare per l'opzione di eliminare i propri dati seguendo il
                link apposito nella mail di conferma della candidatura che ha ricevuto.
              </li>
            </ul>
          </li>
        </ul>
        <h5>Qualora l'utente fornisca a Joppys il consenso a:</h5>
        <ul>
          <li>
            Informarlo di nuovi servizi di Joppys e di offerte speciali che la Societ&agrave; ritiene possano essere
            interessanti per lo stesso, ad esempio via email, e mostrando pubblicit&agrave; su siti web di soggetti
            terzi quali Facebook (laddove sia richiesto il consenso).
          </li>
          <li>
            Inviare informazioni su concorsi e sondaggi e dettagli di offerte promozionali dei Partner di Joppys e
            riguardo ai beni o servizi di altri soggetti terzi selezionati, ad esempio, quelli di marchi relativi a
            salute, bellezza, tempo libero e lifestyle, via email e tramite notifiche push trasmesse dall'App.
          </li>
          <li>
            Trasferire i suoi dati personali ai Partner di Joppys e ad altri soggetti terzi selezionati, ad esempio,
            marchi relativi a bellezza, tempo libero e lifestyle, per consentire loro di inviargli, a loro nome,
            informazioni sui loro beni e servizi.
          </li>
          <li>
            Inserire cookie e utilizzare tecnologie simili, conformemente alla sezione della presente Informativa sulla
            Privacy dedicata all'uso dei cookie, e le informazioni fornite all'utente quando vengono utilizzate tali
            tecnologie.
          </li>
          <li>
            Utilizzare le comunicazioni dei clienti per finalit&agrave; di formazione, registrazione e controllo
            qualit&agrave;. Qualora telefoni al team customer experience di Joppys, e acconsenta non opponendoti, la tua
            chiamata potr&agrave; essere registrata. Verrai informato nel caso in cui venga effettuata tale
            registrazione e ti verr&agrave; offerta la possibilit&agrave; di non acconsentire alla registrazione laddove
            lo desideri, e i mezzi per farlo.
          </li>
          <li>
            Negli altri casi in cui Joppys richieda il consenso dell'utente, la Societ&agrave; utilizzer&agrave; i dati
            per la finalit&agrave; indicata al momento.
          </li>
        </ul>
        <p>
          Per le finalit&agrave; <strong>previste dalla legge:</strong>
        </p>
        <ul>
          <li>Rispondere a richieste di governi o forze dell'ordine che conducono un'indagine.</li>
          <li>Ottemperare a obblighi di legge, regolamentari e di compliance.</li>
        </ul>
        <h5>Revocare il consenso od opporsi altrimenti ad attivit&agrave; di marketing diretto</h5>
        <p>
          Ogni volta che Joppys fa affidamento sul consenso fornito dall'utente, lo stesso potr&agrave; sempre revocare
          tale consenso, sebbene la Societ&agrave; possa avere altre motivazioni legali per eseguire il trattamento dei
          dati per altre finalit&agrave;, quali quelle sopra elencate. In alcuni casi, Joppys &egrave; autorizzata a
          inviare all'utente comunicazioni di marketing diretto senza il suo consenso, laddove si basi sui propri
          interessi legittimi. L'utente gode del diritto assoluto di opporsi all'invio di comunicazioni di marketing
          diretto in qualunque momento. Tale diritto pu&ograve; essere esercitato seguendo le istruzioni contenute nella
          comunicazione, laddove si tratti di un messaggio elettronico, oppure contattando Joppys ai recapiti indicati
          di seguito.
        </p>
        <p>
          Qualora l'utente non voglia pi&ugrave; ricevere comunicazioni inviate da Joppys, potr&agrave; fare clic sul
          pulsante "annulla l'iscrizione" che si trova in fondo alle email inviate oppure, in alternativa, contattare
          Joppys all'indirizzo info@Joppys.pet. In caso di comunicazioni di marketing diretto, l'eliminazione
          dell'indirizzo email dell'utente dal sistema pu&ograve; richiedere 48 ore lavorative.
        </p>
        <ul>
          <li>
            Laddove l'utente non voglia pi&ugrave; ricevere comunicazioni inviate dai Partner e da altri soggetti terzi,
            lo stesso potr&agrave; contattare direttamente il Partner o il soggetto terzo. Nel caso dei Partner, qualora
            l'utente necessiti di assistenza in tale procedura, Joppys sar&agrave; lieta di fare quanto possibile per
            fornire aiuto.
          </li>
          <li>
            Laddove l'utente non voglia pi&ugrave; ricevere notifiche push attraverso l'App, potr&agrave; revocare il
            permesso di ricevere notifiche push per le App modificando le impostazioni del sistema operativo del proprio
            telefono.
          </li>
        </ul>
        <p>
          Laddove l&rsquo;utente abbia disattivato la ricezione via e-mail di comunicazioni commerciali attraverso i
          metodi descritti sopra, potrebbero comunque essere visualizzati annunci pubblicitari non personalizzati mentre
          si &egrave; online se le impostazioni delle preferenze su Facebook sono allineate a un segmento di pubblico
          (predefinito da Facebook) cui &egrave; associata l'attivit&agrave; di Joppys. Joppys non controlla se tali
          annunci pubblicitari vengono visualizzati dall'utente.
        </p>
        <h5>Condivisione dei dati personali con terzi</h5>
        <p>
          Joppys prende molto sul serio la sicurezza e la modalit&agrave; di trattamento dei Dati Personali dell'utente
          e non li vender&agrave; in nessuna circostanza.
          <br />
          Tuttavia, la Societ&agrave; potr&agrave; comunicare i Dati Personali dell'utente a soggetti terzi selezionati,
          compresi i seguenti casi:
        </p>
        <ul>
          <li>
            Qualora l'utente effettui un ordine o interagisca altrimenti con Joppys tramite un sito o un&rsquo;app
            proposti per conto di un soggetto terzo, i dati di contatto dell'utente e i dettagli dell'ordine saranno
            trasferiti al relativo soggetto terzo. Laddove l'utente fornisca i relativi consensi a tali soggetti terzi
            essi potranno anche inviargli comunicazioni commerciali.
          </li>
          <li>
            Comunicazione a fornitori di servizi terzi che svolgono funzioni per nostro conto in relazione alla
            Piattaforma o in altro modo in relazione alla gestione della nostra attivit&agrave;, assumendo candidati e
            per la fornitura di servizi di Joppys (ad esempio, elaborazione dei pagamenti con carta di credito, hosting
            di siti web, conduzione di sondaggi e ricerche di mercato, fornitura di analisi dei social media, fornitura
            di servizi di marketing via e-mail, strumenti di analisi dei dati e per gestire le comunicazioni del
            servizio clienti, comprese le telefonate).
          </li>
          <li>
            Comunicazione ai Partner di Joppys con cui l'utente prenota servizi tramite il Sito o le App di Joppys,
            laddove la Societ&agrave; agisca in qualit&agrave; di agente commerciale per le prenotazioni, ovvero
            direttamente con il Partner, attraverso la Piattaforma, nel caso in cui la Societ&agrave; operi
            esclusivamente come fornitore di tecnologia. Le informazioni dell'utente vengono condivise con i Partner
            affinch&eacute;:
            <ul>
              <li>
                le prenotazioni possano essere facilitate e, laddove necessario, si possa prendere contatto con l'utente
                prima dell'appuntamento;
              </li>
              <li>
                laddove l'utente abbia scelto di ricevere email commerciali dai Partner con cui prenota, tali email
                possano essergli inviate includendo notizie e offerte speciali; ed essi possano migliorare i servizi che
                offrono ai loro clienti e le operazioni commerciali, fornendo dati statistici sui clienti, sia in modo
                aggregato che individuale.
              </li>
              <li>
                Comunicazione a marchi di soggetti terzi con cui Joppys collabora su prodotti, servizi, concorsi e
                campagne.
              </li>
              <li>
                Comunicazione a provider terzi di software per toelettatori, veterinari e ad altre attivit&agrave;
                professionali utilizzati dai Partner di Joppys per garantire che tali soluzioni software terze e Joppys
                Business mostrino ai clienti la disponibilit&agrave; in tempo reale.
              </li>
              <li>
                Comunicazione alle agenzie media e ai partner pubblicitari al fine di condurre campagne marketing mirate
                (per ulteriori informazioni sulle pratiche pubblicitarie online, leggere la sezione della presente
                Informativa sulla Privacy dedicata all'uso dei cookie).
                <br />
                Comunicazione ad altri soggetti terzi, al fine di facilitare l'attivit&agrave; di Joppys e migliorare i
                suoi prodotti, contenuti, servizi o pubblicit&agrave;, incluso, a titolo puramente esemplificativo,
                l'invio di comunicazioni via email o la pubblicazione di recensioni di prodotti e servizi di Joppys o
                dei suoi Partner.
              </li>
              <li>
                Qualora Joppys acquisti, venda o trasferisca attivit&agrave; o beni ovvero sia soggetta a una procedura
                di insolvenza, fallimento o amministrazione controllata. Laddove questo dovesse succedere, Joppys, ove
                opportuno, potrebbe dover comunicare i Dati Personali dell'utente al venditore o all'acquirente di tali
                attivit&agrave; o beni.
              </li>
              <li>
                Qualora Joppys abbia l'obbligo di divulgare o condividere i Dati Personali al fine di ottemperare a
                obblighi di legge, far osservare o applicare termini e condizioni e altri contratti ovvero proteggere i
                diritti, la propriet&agrave; o la sicurezza dei clienti di Joppys o di altri. Tali circostanze includono
                lo scambio di informazioni con altre societ&agrave; e organizzazioni ai fini della protezione dalle
                frodi e della riduzione del rischio di credito.
              </li>
              <li>
                Comunicazione ad autorit&agrave; governative e ad altri soggetti terzi, come previsto o consentito dalla
                legge, ivi compreso, a titolo puramente esemplificativo, in risposta a ordinanze di un tribunale. Joppys
                potr&agrave; inoltre divulgare le informazioni dell'utente laddove abbia ragione di credere che qualcuno
                stia pregiudicando o interferendo con i diritti o la propriet&agrave; di Joppys, altri utenti della sua
                Piattaforma o qualsiasi altro soggetto che possa essere danneggiato da tali attivit&agrave;.
                <br />
                Attenzione: pubblicando Dati Personali in qualunque area della Piattaforma accessibile al pubblico (come
                le sezioni sulle recensioni), tali informazioni potrebbero essere raccolte da soggetti terzi su cui
                Joppys non ha controllo. Joppys non &egrave; responsabile dell'utilizzo di queste informazioni da parte
                di tali soggetti terzi.
                <br />
                L'utente &egrave; pertanto invitato a prestare la debita cautela e attenzione prima di comunicare Dati
                Personali che saranno divulgati in aree pubbliche. Inoltre, l'utente dovrebbe evitare di comunicare su
                aree pubbliche Dati Personali che potrebbero essere utilizzati per identificarlo (come nome, et&agrave;,
                indirizzo di casa o di lavoro oppure nome del datore di lavoro).
              </li>
            </ul>
          </li>
        </ul>
        <h5>Transazioni tramite PayPal</h5>
        <p>
          Tutte le transazioni effettuate tramite PayPal sono soggette all&rsquo;informativa sulla privacy di PayPal che
          pu&ograve; essere consultata al seguente indirizzo: [1]. Qualora l'utente desideri utilizzare PayPal per
          effettuare transazioni attraverso la Piattaforma, lo stesso &egrave; invitato ad assicurarsi di accettare le
          condizioni contenute nell&rsquo;informativa sulla privacy di PayPal.
        </p>
        <h5>Diritti dell'utente in relazione ai dati personali</h5>
        <p>
          In relazione ai dati personali dell'utente conservati da Joppys, l'utente ha diritto a richiedere di:
          <br />
          Ottenere una copia dei dati personali.
          <br />
          Correggere, cancellare o limitare il trattamento dei suoi dati personali.
          <br />
          Ottenere i dati personali forniti a Joppys sulla base dell'esecuzione di un contratto o con il proprio
          consenso, in un formato leggibile meccanicamente e strutturato, e chiedere che tali informazioni vengano
          trasferite a un'altra organizzazione.
          <br />
          Opporsi al trattamento dei suoi dati personali in alcune circostanze (in particolare, laddove Joppys non sia
          tenuta a eseguire il trattamento dei dati per adempiere a obblighi contrattuali o di legge di altro tipo
          ovvero laddove stia utilizzando i dati per attivit&agrave; di marketing diretto). <br />
          Nel primo caso, l'utente potr&agrave; visualizzare o modificare le informazioni fornite a Joppys eseguendo
          l'accesso al proprio account online.
          <br />
          In alcune situazioni, i diritti dell'utente relativi ai dati personali sono limitati. Ad esempio, Joppys
          potr&agrave; continuare a eseguire il trattamento dei Dati Personali di cui l'utente abbia richiesto la
          cancellazione, nel caso in cui, soddisfacendo la sua richiesta, si rivelerebbero dati personali di un'altra
          persona ovvero laddove la Societ&agrave; sia tenuta per legge a farlo o abbia un motivo preminente e
          legittimo.
          <br />
          L'utente avr&agrave; inoltre il diritto di presentare un reclamo laddove ritenga che il trattamento dei suoi
          dati personali non sia stato corretto. L'utente &egrave; incoraggiato a rivolgersi in primo luogo a Joppys;
          tuttavia, nella misura in cui tali diritti siano esercitabili, lo stesso ha diritto di presentare reclamo
          direttamente all'autorit&agrave; di vigilanza competente.
          <br />
          Qualora l'utente desideri esercitare uno qualunque dei suddetti diritti, potr&agrave; contattare Joppys
          scrivendo all'indirizzo info@Joppys.pet. Si fa presente, tuttavia, che non saranno fornite informazioni
          finanziarie senza verifica.
        </p>
        <h5>Quali disposizioni si applicano agli altri siti collegati alla Piattaforma di Joppys?</h5>
        <p>
          Joppys non &egrave; responsabile delle pratiche utilizzate dai siti collegati alla o dalla sua Piattaforma,
          n&eacute; delle informazioni o dei contenuti presenti negli stessi. Spesso, i collegamenti ad altri siti sono
          forniti esclusivamente per rinviare a informazioni su argomenti che potrebbero essere utili agli utenti della
          Piattaforma.
          <br />
          Si ricorda all'utente che la presente Informativa sulla Privacy non &egrave; pi&ugrave; efficace nel caso in
          cui si utilizzi un collegamento che va dalla Piattaforma di Joppys ad un altro sito. La navigazione e le
          interazioni su qualunque altro sito, inclusi siti che hanno un collegamento con la Piattaforma di Joppys, sono
          soggette alle norme e alle informative proprie di tale sito. Leggere sempre tali norme e informative prima di
          procedere.
        </p>
        <h5>Social media e contenuti generati dall&rsquo;utente</h5>
        <p>
          Alcune Piattaforme di Joppys consentono agli utenti di inviare i loro contenuti. Si ricorda che qualunque
          contenuto inviato ad una delle Piattaforme di Joppys pu&ograve; essere reso pubblico e l'utente dovrebbe
          essere cauto nel fornire alcuni dati personali, come ad esempio informazioni finanziarie o l'indirizzo,
          tramite tali Piattaforme. Laddove l'utente pubblichi dati personali su una delle pagine presenti sui social
          media, quali Facebook o Instagram, Joppys non &egrave; responsabile di eventuali azioni compiute da altri.
          Fare riferimento anche alle rispettive informative sulla privacy e sull'uso dei cookie delle piattaforme
          social media che si utilizzano.
        </p>
        <h5>Modifiche relative all&rsquo;Informativa sulla Privacy</h5>
        <p>
          In caso di modifiche apportate alle informative sulla privacy e alle procedure, Joppys pubblicher&agrave; tali
          modifiche sulla sua Piattaforma per rendere consapevole l'utente delle informazioni che raccoglie, di come le
          utilizza e delle circostanze in cui potrebbe divulgarle.
          <br />
          Luogo di conservazione dei dati personali
          <br />I dati che Joppys raccoglie dall'utente potrebbero essere trasferiti e conservati in una destinazione al
          di fuori dallo Spazio Economico Europeo (SEE), incluso il Regno Unito, anche per far s&igrave; che il loro
          trattamento sia eseguito da soggetti terzi selezionati, al fine di facilitare l'attivit&agrave; di Joppys. I
          paesi al di fuori del SEE potrebbero non avere normative che offrono lo stesso livello di protezione dei dati
          personali garantito da quelle vigenti all'interno del SEE. In tal caso, i dati personali sono opportunamente
          tutelati da clausole contrattuali standard approvate dalla Commissione Europea, da un'adeguata certificazione
          Privacy Shield e da un regolamento aziendale del fornitore vincolante per gli incaricati del trattamento.
          &Egrave; possibile richiedere copia del relativo meccanismo di tutela della privacy scrivendo
          all&rsquo;indirizzo info@joppys.pet.
        </p>
        <h5>Conservazione</h5>
        <p>
          Joppys conserva i Dati Personali per il tempo necessario a svolgere le attivit&agrave; indicate nella presente
          Informativa sulla Privacy o altrimenti comunicate all'utente ovvero per il periodo consentito dalla normativa
          vigente. Ad esempio:
          <br />
          Qualora l'utente presenti la propria candidatura per una posizione presso Joppys, sia direttamente che
          indirettamente, le informazioni che fornir&agrave; saranno conservate per un periodo di 12 mesi e potranno
          essere richiamate in caso di invio di future candidature di lavoro presso Joppys. L'utente pu&ograve; optare
          per l'opzione di eliminare i propri dati seguendo il link apposito nella mail di conferma della candidatura
          che ha ricevuto.
          <br />
          se ci contattate telefonicamente e non richiedete un opt-out, conserveremo le registrazioni delle chiamate per
          un massimo di 90 giorni dalla data della chiamata.
        </p>
        <h5>Informativa sull'uso dei cookie</h5>
        <p>
          La Piattaforma di Joppys, in comune con altri siti commerciali e app, utilizza tecnologie standard, inclusi
          cookie e strumenti simili, come registri di server web, web beacon, token, pixel tag, archiviazione locale,
          identificatori di dispositivo e ID di localizzazione (collettivamente denominati "Cookie" nella presente
          Informativa sulla Privacy) al fine di migliorare l&rsquo;esperienza dell&rsquo;utente, migliorare il nostro
          sito e fornire offerte personalizzate su Joppys e altri siti.
          <br />
          Si prega di consultare la tabella sottostante per l&rsquo;attuale ripartizione dei Cookie utilizzati sulla
          nostra Piattaforma.
        </p>
        <h5>Elenco dei cookie</h5>
        <p>
          Un cookie &egrave; una piccola porzione di dati (file di testo) che un sito Web, se visitato da un utente,
          chiede al browser di memorizzarlo sul dispositivo per ricordare le sue informazioni, quali la lingua preferita
          o i dati di accesso. Questi cookie sono da noi impostati e denominati cookie di prima parte. Utilizziamo
          inoltre cookie di terza parte - ovvero i cookie di un dominio diverso da quello del sito Web che si sta
          visitando - per i nostri tentativi pubblicitari e di marketing. In particolare, utilizziamo i cookie e altre
          tecnologie di tracciamento per i seguenti scopi:
        </p>
        <h5>Cookie strettamente necessari</h5>
        <p>
          Questi cookie sono necessari per il funzionamento del sito e non possono essere disattivati nei nostri
          sistemi. Di solito vengono impostati solo in risposta alle azioni da te effettuate che costituiscono una
          richiesta di servizi, come l'impostazione delle preferenze di privacy, l'accesso o la compilazione di moduli.
          &Egrave; possibile impostare il browser per bloccare o avere avvisi riguardo questi cookie, ma di conseguenza
          alcune parti del sito non funzioneranno. Questi cookie non archiviano informazioni personali.
        </p>
        <h5>Cookie di prestazione</h5>
        <p>
          Questi cookie ci permettono di contare le visite e fonti di traffico in modo da poter misurare e migliorare le
          prestazioni del nostro sito. Ci aiutano a sapere quali sono le pagine pi&ugrave; e meno popolari e vedere come
          i visitatori si muovono intorno al sito. Tutte le informazioni raccolte dai cookie sono aggregate e quindi
          anonime. Se non consenti questi cookie, non sapremo quando hai visitato il nostro sito.
        </p>
        <h5>Cookie di funzionalit&agrave;</h5>
        <p>
          Questi cookie consentono al sito di fornire funzionalit&agrave; e personalizzazione avanzate. Possono essere
          impostati da noi o da provider di terze parti i cui servizi sono stati aggiunto alle nostre pagine. Se non si
          autorizzano questi cookie, alcuni o tutti questi servizi potrebbero non funzionare correttamente.
        </p>
        <h5>Cookie per pubblicit&agrave; mirata</h5>
        <p>
          Questi cookie possono essere impostati tramite il nostro sito dai nostri partner pubblicitari. Possono essere
          utilizzati da queste aziende per costruire un profilo dei tuoi interessi e mostrarti annunci pertinenti su
          altri siti. Non memorizzano direttamente informazioni personali, ma sono basati unicamente sull'individuazione
          del tuo browser e del tuo dispositivo internet. Se non si accettano questi cookie, riceverai una
          pubblicit&agrave; meno mirata
          <br />
          Le societ&agrave; terze di cui Joppys si avvale per scopi pubblicitari e di targeting hanno le proprie
          informative sulla privacy che andrebbero lette attentamente.
        </p>
      </Card>
    </Container>
  )
}
