import React, { useState } from "react"
import { Form, Button, Alert, Card, Container, Col, Row, FloatingLabel } from "react-bootstrap"

import { useAuth } from "context/AuthContext"
import { Link, useNavigate } from "react-router-dom"

import {
  validateDigit,
  validateEmail,
  validateLength,
  validateLower,
  validateSpecial,
  validateUpper,
} from "scripts/Validation"

import GoogleButtonLabel from "components/GoogleButtonLabel"
import { StringList } from "components/StringList"

export default function SignUp() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [check, setCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorList, setErrorList] = useState()

  const { signup, loginGoogle } = useAuth()

  const navigate = useNavigate()

  function goToDashboard() {
    navigate("/business/dashboard")
  }

  async function handleSubmit(e) {
    let newErrorList = []

    e.preventDefault()
    if (!validateEmail(email)) {
      newErrorList.push("Indirizzo e-mail non valido")
    }
    if (!validateUpper(password)) {
      newErrorList.push("Scegli una password con almeno un carattere maiuscolo")
    }
    if (!validateLower(password)) {
      newErrorList.push("Scegli una password con almeno un carattere minuscolo")
    }
    if (!validateDigit(password)) {
      newErrorList.push("Scegli una password con almeno un numero")
    }
    if (!validateSpecial(password)) {
      newErrorList.push("Scegli una password con almeno un carattere speciale")
    }
    if (!validateLength(password, 8)) {
      newErrorList.push("Scegli una password di almeno 8 caratteri")
    }
    if (password !== passwordConfirm) {
      newErrorList.push("Le password non coincidono")
    }
    if (password !== passwordConfirm) {
      newErrorList.push("Le password non coincidono")
    }
    if (!check) {
      newErrorList.push("Devi prestare il consenso")
    }
    if (newErrorList.length) {
      setErrorList(newErrorList)
      return false
    }
    setErrorList([])
    try {
      setLoading(true)
      await signup(email, password)
      goToDashboard()
    } catch (e) {
      setErrorList([e.message])
    }
    setLoading(false)
  }

  async function handleGoogle() {
    setErrorList([])
    try {
      setLoading(true)
      await loginGoogle(email, password)
      goToDashboard()
    } catch (e) {
      setErrorList(e.code)
    }
    setLoading(false)
  }

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={5}>
            <Card className="p-4">
              <Card.Body>
                <div className="mb-4">
                  <h3 className="mb-4">Registrati su Joppys</h3>
                  <span>
                    Sei già registrato? Fai{" "}
                    <Link to="/business/login" preventScrollReset={true}>
                      Login!
                    </Link>
                  </span>
                </div>
                <Button onClick={handleGoogle} disabled={loading} size="lg" variant="light w-100">
                  <GoogleButtonLabel />
                </Button>
                <div className="d-inline-flex align-items-center w-100 my-2">
                  <hr className="w-100" />
                  <span className="w-50 text-center">o</span>
                  <hr className="w-100" />
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-2">
                    <FloatingLabel controlId="email" label="Email" className="mb-2">
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // type="email"
                        placeholder="Email"
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <FloatingLabel label="Password" className="mb-2" controlId="password">
                      <Form.Control
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel controlId="floatingConfirmPassword" label="Ripeti Password" className="mb-2">
                      <Form.Control
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        type="password"
                        placeholder="Conferma Password"
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Check className="small mb-3" iId="confirmCheckbox">
                    <Form.Check.Input checked={check} onChange={(e) => setCheck(e.target.checked)} />
                    <Form.Check.Label>
                      {"Creando un account accetti i "}
                      <Link to="/" target="_blank" rel="noopener noreferrer">
                        Termini e le Condizioni di Servizio di Joppys
                      </Link>
                      {", e confermi di aver letto e compreso e l'"}
                      <Link to="/" target="_blank" rel="noopener noreferrer">
                        {"Informativa sulla Privacy"}
                      </Link>
                      .
                    </Form.Check.Label>
                    {/* <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback> */}
                  </Form.Check>

                  {errorList?.length > 0 && (
                    <Alert className="mb-2 small" variant="danger">
                      <StringList list={errorList}></StringList>
                    </Alert>
                  )}
                  <Button disabled={loading} variant="primary" className="w-100 mb-2" size="lg" type="submit">
                    Registrati
                  </Button>
                  {/* <hr className="w-100" />
                  <div className="small mt-2">
                    {"Creando un account accetti i "}
                    <Link to="/" target="_blank" rel="noopener noreferrer">
                      Termini e le Condizioni di Servizio di Joppys
                    </Link>
                    {", e confermi di aver letto e compreso e l'"}
                    <Link to="/" target="_blank" rel="noopener noreferrer">
                      {"Informativa sulla Privacy"}
                    </Link>
                    .
                  </div> */}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
